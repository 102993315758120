import React, { useEffect, useState } from "react";
import "./Board.css";
import Field from "../field/field";
import Piece from "../Piece/piece";

const fenToBoard = (fen) => {
  // Split FEN string into its components
  const [position] = fen.split(" ");

  // Split position string into rows
  const rows = position.split("/");

  // Create a 2D array to represent the board
  const board = Array(8)
    .fill([])
    .map(() => Array(8).fill({}));

  // Loop over the rows and columns of the board, placing the pieces
  for (let row = 0; row < 8; row++) {
    let col = 0;
    for (let i = 0; i < rows[row].length; i++) {
      const char = rows[row][i];
      if (isNaN(char)) {
        // If char is a piece
        const isWhite = char.toUpperCase() === char;
        const pieceType = char.toLowerCase();
        board[row][col] = {
          type: pieceType,
          isWhite: isWhite,
        };
        col++;
      } else {
        // If char is a number (empty squares)
        col += parseInt(char, 10);
      }
    }
  }

  return board;
};

const Board = ({ fen }) => {
  const [selectedPiece, setSelectedPiece] = useState();
  const [board, setBoard] = useState(
    Array(8)
      .fill(null)
      .map(() => Array(8).fill(null))
  );

  const handleDragStart = (row, col) => {
    setSelectedPiece({
      row,
      col,
      type: board[row][col].type,
      isWhite: board[row][col].isWhite,
    });
  };

  useEffect(() => {
    setBoard(fenToBoard(fen));
  }, [fen]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, row, col) => {
    event.preventDefault();

    const [file, rank] = [col, row];

    if (selectedPiece) {
      // Create a copy of the board state
      const newBoard = [...board];

      // Update the board state to reflect the new position of the selected piece
      newBoard[rank][file] = {
        type: selectedPiece.type,
        isWhite: selectedPiece.isWhite,
      };
      newBoard[selectedPiece.row][selectedPiece.col] = null;

      // Update the board state with the new piece positions
      setBoard(newBoard);

      setSelectedPiece(null);
    }
  };

  return (
    <div className="board">
      {board.map((row, i) => {
        return row.map((piece, j) => {
          const isWhite = (i + j) % 2 === 0;
          const backgroundColor = isWhite ? "white" : "black";
          return (
            <Field
              key={`${i}-${j}`}
              className={backgroundColor}
              onDragOverEvent={(e) => handleDragOver(e)}
              onDropEvent={(e) => handleDrop(e, i, j)}
            >
              {piece && piece.type && (
                <Piece
                  type={piece.type}
                  isWhite={piece.isWhite}
                  handleDrag={(e) => handleDragStart(i, j)}
                />
              )}
            </Field>
          );
        });
      })}
    </div>
  );
};
export default Board;
