
import './App.css';
import Board from './components/Board/Board';


function App() {
  return (
    <>
        <Board fen="rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR" />
        <button>{'<'}</button>
        <button>{'>'}</button>
    </>
  );
}

export default App;
